// -------------- CA EVENT Logic -------------- //
import { isDateInRange } from '../utils/isDateInRange';
import {
  eventStartDate as startDate,
  eventEndDate as endDate,
} from './eventDates';
const isInRange = isDateInRange(startDate, endDate);
// ------------------------------------------------//

const DISCOUNTS = {
  '20%': 0.2,
  '30%': 0.3,
  '40%': 0.4,
  '50%': 0.5,
  BNDLE: 0.6,
  CSS: 0.6,
  Military: 0.6,
  'Prev Tx': 0.7,
};

// -------------- CA EVENT Logic -------------- //
if (isInRange) {
  DISCOUNTS['CA EVENT'] = 0.6;
  DISCOUNTS['CA EVENT - NEW'] = 0.6;
}
// ------------------------------------------------//

export default DISCOUNTS;
