export { default as DISCOUNTS } from './DISCOUNTS';
export * from './FEES';
export * from './privateSales';
export * from './defaultActive';
export * from './initialOptionValues';
export * from './states.ts';
export * from './lenders.ts';
// export * from './lenders-old.js';
// export * from './states-old.js';

// Deprecated
// export const pifDiscount = 0.05

export const alphaeon28Verbiage = `For equal monthly installments, paying over 28 mos. vs. 36 mos. satisfies Alphaeon’s 3.5% min. payment requirement.`;
