import React, { useContext, useEffect } from 'react';
import { Formik, Form, Field } from 'formik';
import { QuoteContext } from '.';
import {
  PRIVATE_SALES,
  FEES,
  DISCOUNTS,
  STATES,
  SelectLender,
} from '../constants';

export function SelectionsSection({ splitFinanceFeature }) {
  const { active, setActive, options, setOptions } = useContext(QuoteContext);

  // -------  Discount Dropdown Logic ------- //
  // Don't list BNDLE and CSS in the global Discount Dropdown
  let DISCOUNTS_DROPDOWN = Object.keys(DISCOUNTS).filter(
    (key) => !['BNDLE', 'CSS'].includes(key)
  );
  // Don't list Event discount in the global Discount Dropdown if Pre-Sale is active
  if (active.preSale) {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
      (key) => !['Event'].includes(key)
    );
  } else {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
      (key) => !['60%'].includes(key)
    );
  }

  // Exclude Prev Tx discount when a Private Sale is selected
  if (active.privateSale !== 'None') {
    DISCOUNTS_DROPDOWN = DISCOUNTS_DROPDOWN.filter(
      (key) => !['Prev Tx'].includes(key)
    );
  }

  // --------- Lenders Dropdown Logic --------- //
  let STATE_LENDERS = [...active.state.lenders]
    .sort((a, b) => a.name.localeCompare(b.name))
    .filter(
      (lender) =>
        lender.display && lender.financePlan.includes(active.financePlan)
    ); // Filter out lenders that don't accept the selected finance plan

  // ---------- myMilan Logic ---------- //
  // Check if the SPLIT_FINANCE_FEATURE is enabled
  if (splitFinanceFeature) {
    // Check if Split Loan is enabled in any Option (if the split loan checkbox is checked in any Option)
    const isSplitLoanEnabled = Object.values(options).some(
      (option) => option.splitLoan.isEnabled
    );
    if (isSplitLoanEnabled) {
      STATE_LENDERS = STATE_LENDERS.filter((lender) => lender.canSplit);
    }
  }
  // ------------------------------------- //

  const onChangeHandler = (value, fieldName) => {
    switch (fieldName) {
      case 'discount':
        setActive({ ...active, discount: value });
        const optionsClone = { ...options }; // Shallow Clone
        let discount = value;
        // Update "discount" property of each area
        Object.values(optionsClone).forEach((option) =>
          option.items.forEach((area) => {
            area.discount =
              area.parentAreaId === 'bundles' &&
              discount !== 'Military' &&
              discount !== 'Event'
                ? 'BNDLE'
                : discount;
          })
        );
        setOptions(optionsClone);
        break;
      case 'fees':
        setActive({ ...active, fee: FEES.find((fee) => fee.id === value) });
        break;
      case 'private_sale':
        setActive({ ...active, privateSale: value });
        break;
      case 'lender':
        setActive({
          ...active,
          lender:
            active.state.lenders.find((lender) => lender.name === value) ||
            SelectLender,
        });
        break;
      // case 'clientStatus':
      //   setActive({ ...active, newClient: (value === 'false' || value === false) ? false : true })
      //   break
      case 'state':
        if (value === STATES[0].name) return;
        const state = STATES.find((state) => state.name === value);
        // this is important when switching between states, as the active lender might have a different interest rate in the new state
        const lender =
          state.lenders.find((lender) => lender.name === active.lender.name) ||
          SelectLender;
        localStorage.setItem('stateName', value);
        setActive({
          ...active,
          state: state,
          lender: lender,
        });
        break;
      default:
        return;
    }
  };

  const initialValues = {
    discount: active.discount,
    fees: active.fee.id,
    private_sale: active.privateSale,
    lender: active.lender.name,
    clientStatus: active.newClient,
    state: active.state.name,
  };

  useEffect(() => {
    // Retrieve the value from localStorage
    const storedValue = localStorage.getItem('stateName');
    if (storedValue) onChangeHandler(storedValue, 'state');
  }, []);

  return (
    <section
      id='selections-section'
      className='container-fluid py-4 pt-sm-5 print-hide'
    >
      <Formik initialValues={initialValues} enableReinitialize>
        <Form className='row justify-content-center gy-4 padding-x'>
          <div className='col-auto'>
            <h3
              className={`h6 pb-1 ${
                active.state.name === STATES[0].name
                  ? 'milan-text-red'
                  : 'milan-text-navy'
              }`}
            >
              State {active.state.name === STATES[0].name && <sup>*</sup>}
            </h3>
            <div>
              <Field
                as='select'
                id='state'
                name='state'
                className={`form-select ${
                  active.state.name === STATES[0].name ? 'border-danger' : ''
                }`}
                onChange={(e) => onChangeHandler(e.target.value, 'state')}
              >
                {STATES.map((state) => (
                  <option
                    key={state.name}
                    value={state.name}
                    disabled={
                      active.lender.name !== SelectLender.name &&
                      state.lenders.every(
                        (lender) => lender.name !== active.lender.name
                      )
                    }
                  >
                    {state.name}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className='col-auto'>
            <h3
              className={`h6 pb-1 ${
                active.lender.name === SelectLender.name
                  ? 'milan-text-red'
                  : 'milan-text-navy'
              }`}
            >
              Lender {active.lender.name === SelectLender.name && <sup>*</sup>}
            </h3>
            <div>
              <Field
                as='select'
                id='lender'
                name='lender'
                className={`form-select ${
                  active.lender.name === SelectLender.name
                    ? 'border-danger'
                    : ''
                }`}
                onChange={(e) => onChangeHandler(e.target.value, 'lender')}
              >
                <option value={SelectLender.name}>{SelectLender.name}</option>
                {STATE_LENDERS.map((lender) => (
                  <option key={lender.name} value={lender.name}>
                    {lender.name}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className='col-auto'>
            <h3 className='h6 pb-1 milan-text-navy'>Single Area Discount</h3>
            <div>
              <Field
                as='select'
                id='discount'
                name='discount'
                className='form-select'
                onChange={(e) => onChangeHandler(e.target.value, 'discount')}
              >
                {DISCOUNTS_DROPDOWN.map((discountKey, d) => (
                  <option key={d} value={discountKey}>
                    {discountKey}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          <div className='col-auto'>
            <h3 className='h6 pb-1 milan-text-navy'>Code</h3>
            <div>
              <Field
                as='select'
                id='private_sale'
                name='private_sale'
                className='form-select'
                onChange={(e) =>
                  onChangeHandler(e.target.value, 'private_sale')
                }
                disabled={active.discount === 'Prev Tx'}
              >
                {Object.keys(PRIVATE_SALES).map((code, x) => (
                  <option key={x} value={code}>
                    {code}
                  </option>
                ))}
              </Field>
            </div>
          </div>
          {active.state.name === 'Colorado' && (
            <div className='col-auto'>
              <h3 className='h6 pb-1 milan-text-navy'>Fees</h3>
              <div>
                <Field
                  as='select'
                  id='fees'
                  name='fees'
                  className='form-select'
                  onChange={(e) => onChangeHandler(e.target.value, 'fees')}
                >
                  {FEES.map((fee) => (
                    <option key={fee.id} value={fee.id}>
                      {fee.name}
                    </option>
                  ))}
                </Field>
              </div>
            </div>
          )}
        </Form>
      </Formik>
    </section>
  );
}

export default SelectionsSection;
//
